import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import blogItemsJSON from '../../data/blog-items.json';
import './index.css';

declare const hljs: any;

const { blogItems } = blogItemsJSON;
const possibleSlugs = blogItems.map(x => x.slug);

function BlogPost() {
  const params = useParams();
  const isValidSlug = params.slug && possibleSlugs.includes(params.slug);
  const [htmlContent, setHtmlContent] = useState<{[key: string]: string}>({});
  const blogContentRef = useRef<HTMLDivElement>(null);

  let blogMetadata = null;
  if (isValidSlug) {
    blogMetadata = blogItems.filter(x => x.slug === params.slug)[0];
  }

  // fetch all blog content only once
  useEffect(() => {
    const nextHtmlContentData: {[key: string]: string} = {};
    const fetchHtmlContentData = async function() {
      for (const slug of possibleSlugs) {
        const res = await axios.get(`/blog/${slug}.html`)
        nextHtmlContentData[slug] = res.data;
      }
    };
    fetchHtmlContentData().then(() => {
      setHtmlContent(nextHtmlContentData);
    });
  }, []);

  // highlight code
  useEffect(() => {
    if (!blogContentRef.current) {
      return;
    }
    blogContentRef.current.querySelectorAll('pre').forEach((el) => {
      hljs.highlightElement(el);
    });
  }, [blogContentRef.current]);

  return (
    <div className="BlogPost">
      { isValidSlug && params.slug &&
        <div className="blog-post">
          <h1 className="blog-title">
            { blogMetadata && blogMetadata.subject }
          </h1>
          <div className="blog-published">
            { blogMetadata && blogMetadata.datePublished }
          </div>
          <div className="blog-content" ref={blogContentRef}>
            <div dangerouslySetInnerHTML={ {__html: htmlContent[params.slug]} } />
          </div>
        </div>
      }
      {
        !isValidSlug &&
        <div>
          Slug is invalid!
        </div>
      }
    </div>
  )
}

export default BlogPost;
