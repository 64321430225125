import React from 'react';
import { Link, useLocation } from "react-router-dom";
import './index.css';

interface Props {
  className?: string,
  children: React.ReactNode
}

function PageContainer({className, children}: Props) {
  const location = useLocation();

  const classNames = className
    ? ["PageContainer", className]
    : ["PageContainer"];

  return (
    <div className={classNames.join(' ')}>
      <div className="side-bar">
        <div className="side-bar-name">
          Daniel Jamrozik
        </div>
        <div className="side-bar-picture">
          <img src="/images/personal-photo-color-halftone-lg.png" alt="it me" />
        </div>
        <div className="side-bar-menu">
          <div className={`side-bar-menu-item ${location.pathname === '/' && 'selected'}`}>
            <Link to="/">Home</Link>
          </div>
          <div className={`side-bar-menu-item ${location.pathname === '/blog' && 'selected'}`}>
            <Link to="/blog">Blog</Link>
          </div>
        </div>
      </div>
      <div className="content-wrapper">
        <div className="top-bar">
          <div className="top-bar-left">
            <h2>Daniel Jamrozik</h2>
          </div>
          <div className="top-bar-right">
            <div className={`top-bar-menu-item ${location.pathname === '/' && 'selected'}`}>
              <Link to="/">Home</Link>
            </div>
            <div className={`top-bar-menu-item ${location.pathname === '/blog' && 'selected'}`}>
              <Link to="/blog">Blog</Link>
            </div>
          </div>
        </div>
        <div className="content">
          { children }
        </div>
      </div>
    </div>
  )
}

export default PageContainer;
