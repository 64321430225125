import React from 'react';
import { Link } from 'react-router-dom';
import PageContainer from "../../components/PageContainer";
import blogItemsJSON from '../../data/blog-items.json';
import './index.css';

function BlogOverview() {
  const { blogItems } = blogItemsJSON;
  return (
    <div className="BlogOverview">
      <PageContainer>
        <h2 className="blog-overview-header">
          Recent Posts
        </h2>
          {
            blogItems.map((x, i) => {
              return (
                <div className="blog-item" key={i}>
                  <Link to={`/blog/${x.slug}`}>
                    <div className="blog-title">
                      { x.subject }
                    </div>
                  </Link>
                  <div className="blog-blurb">
                    { x.blurb }
                  </div>
                </div>
              )
            })
          }
      </PageContainer>
    </div>
  )
}

export default BlogOverview;
