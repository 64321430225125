import React from "react";
import PageContainer from "../../components/PageContainer";
import "./index.css";

function Home() {
  return (
    <PageContainer className="Home">
      <div className="info-section about-section">
        <h2>About Me</h2>
        <div className="info-section-text">
          Hi, my name is Daniel Jamrozik. I’m a full stack developer based in
          Chicago, IL. Currently, I'm working full time at Chainalysis on
          internal research projects. I enjoy working on side projects to learn
          new technologies or build cool products for people. I've built{" "}
          <a href="https://dailydefi.org/">Daily Defi</a>, &nbsp;
          <a href="https://stakedvalue.nimblecode.dev/">StakedValue</a>, &nbsp;
          <a href="https://nimblecode.dev/">nimblecode.dev</a>, and other side
          projects which are listed below.
        </div>
      </div>
      <div className="info-section">
        <h2>Work Experience</h2>
        <div className="info-section-text">
          <div className="recent-work-item">
            <div className="recent-work-title">
              <a
                href="https://ellipticdefisolutions.com"
                target="_blank"
                rel="noreferrer"
              >
                Chainalysis
              </a>
              <div className="recent-work-subtext">
                Full-time, March 2022 - Current
              </div>
            </div>
            <div className="recent-work-info">
              <p>
                Research team development focused on tools for investigators and
                researchers. Used React, AG Grid, graphology on the front-end to
                visualize blockchain transaction data. On the back-end, built
                scalable APIs with Flask to serve application and blockchain
                state. These new tools increased usage of an internal block
                explorer by 3x.
              </p>
            </div>
          </div>
          <div className="recent-work-item">
            <div className="recent-work-title">
              <a
                href="https://ellipticdefisolutions.com"
                target="_blank"
                rel="noreferrer"
              >
                Elliptic Defi Solutions
              </a>
              <div className="recent-work-subtext">
                Full-time, December 2020 - March 2022
              </div>
            </div>
            <div className="recent-work-info">
              <p>
                Self-started contracting firm which specializes working with
                decentralized applications. Provides services in building clean
                user facing web3 front-ends, limited smart contract development,
                as well scalable and modular back-end services. Used React,
                TypeScript, Node.js, AWS, and Solidity.
              </p>
            </div>
          </div>
          <div className="recent-work-item">
            <div className="recent-work-title">
              <a href="https://peak6.com/" target="_blank" rel="noreferrer">
                PEAK6
              </a>
              <div className="recent-work-subtext">
                Full-time, February 2018 - November 2020
              </div>
            </div>
            <div className="recent-work-info">
              <p>
                Worked in a variety of software engineering roles at PEAK6, with
                the majority of time spent as a full stack developer on the
                Trading Tools team. This entailed helping build out a toolbox
                for internal users which allowed for creating custom
                applications to parse, analyze, and react to trading data.
              </p>
            </div>
          </div>
          <div className="recent-work-item">
            <div className="recent-work-title">
              <a href="https://justworks.com/" target="_blank" rel="noreferrer">
                Justworks
              </a>
              <div className="recent-work-subtext">Internship, Summer 2017</div>
            </div>
            <div className="recent-work-info">
              <p>
                Interned on the HR Information Systems team, where I covered
                both client facing and internal tools within the web app. Main
                project was building a page from scratch for account managers to
                better understand their accounts. Used Ruby on Rails on the
                back-end and React on the front-end.
              </p>
            </div>
          </div>
          <div className="recent-work-item">
            <div className="recent-work-title">
              <a href="https://yahoo.com/" target="_blank" rel="noreferrer">
                Yahoo!
              </a>
              <div className="recent-work-subtext">Internship, Summer 2016</div>
            </div>
            <div className="recent-work-info">
              <p>
                Full stack web developer focusing on AMP article pages.
                Published changes which affect 500,000 Yahoo users a day and the
                way they experience reading Yahoo articles. Includes adding a
                'What to Read Next' section and updating styles. Also, changed
                the way by which ads are rendered to users in AMP.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="info-section projects-section">
        <h2>Personal Projects</h2>
        <div className="info-section-text">
          <div className="recent-work-item">
            <div className="recent-work-title">
              <a
                href="https://nimblecode.dev/"
                target="_blank"
                rel="noreferrer"
              >
                nimblecode.dev
              </a>
            </div>
            <div className="recent-work-info">
              Fairly generic coding blog and collection of small tools that I
              find useful. I use this as a notebook for problems I've solved on
              my side projects. Ideally that is a straightforward way get page
              views and to serve as something I can look back on.
            </div>
          </div>
        </div>
        <div className="info-section-text">
          <div className="recent-work-item">
            <div className="recent-work-title">
              <a href="https://dailydefi.org/" target="_blank" rel="noreferrer">
                Daily Defi
              </a>
            </div>
            <div className="recent-work-info">
              Blog which focuses on creating tutorials for decentralized finance
              space. Does ok on just basic SEO (5k visitors), with most people
              using the impermanent loss calculator.
            </div>
          </div>
          <div className="recent-work-item">
            <div className="recent-work-title">
              <a
                href="https://stakedvalue.nimblecode.dev/"
                target="_blank"
                rel="noreferrer"
              >
                Staked Value
              </a>
            </div>
            <div className="recent-work-info">
              <p>
                Utility for users of{" "}
                <a href="https://bao.finance/">bao.finance</a> to track staking
                rewards and portfolio allocation.
              </p>
            </div>
          </div>
          <div className="recent-work-item">
            <div className="recent-work-title">
              <a
                href="https://json-to-go.nimblecode.dev/"
                target="_blank"
                rel="noreferrer"
              >
                Json to Go
              </a>
            </div>
            <div className="recent-work-info">
              Converts raw JSON to a Golang struct. A tool I personally use when
              creating handling and sending HTTP messages in a Go webserver.
            </div>
          </div>
          <div className="recent-work-item">
            <div className="recent-work-title">
              <a
                href="https://spotifycharts.nimblecode.dev/"
                target="_blank"
                rel="noreferrer"
              >
                Spotify Charts
              </a>
            </div>
            <div className="recent-work-info">
              Personalized spotify charts. See your top artists and tracks in
              three different time frames.
            </div>
          </div>
          <div className="recent-work-item">
            <div className="recent-work-title">
              <a
                href="https://reply-as.nimblecode.dev/"
                target="_blank"
                rel="noreferrer"
              >
                Reply-As
              </a>
            </div>
            <div className="recent-work-info">
              Given the URL of a tweet and a selected personality, this tool
              will use GPT3.5 to create a witty response in the voice of that
              personality.
            </div>
          </div>
          <div className="recent-work-item">
            <div className="recent-work-title">
              <a
                href="https://chess-activity-vis.nimblecode.dev/"
                target="_blank"
                rel="noreferrer"
              >
                Chess Activity Vis
              </a>
            </div>
            <div className="recent-work-info">
              Shows chess.com games played in the style of the Github commit
              activity grid.
            </div>
          </div>
        </div>
      </div>
      <div className="info-section connect-section">
        <h2>Connect</h2>
        <div className="info-section-text">
          <div className="connect-item">
            <a href="mailto:djamrozik@protonmail.com">Email</a>
            <div className="connect-subtext">
              (djamrozik@protonmail.com) - (I don't check this often, not my
              main email)
            </div>
          </div>
          <div className="connect-item">
            <a href="https://github.com/djamrozik">Github</a>
            <div className="connect-subtext">(github.com/djamrozik)</div>
          </div>
          <div className="connect-item">
            <a href="https://www.linkedin.com/in/djamrozik/">LinkedIn</a>
            <div className="connect-subtext">(/in/djamrozik)</div>
          </div>
        </div>
      </div>
    </PageContainer>
  );
}

export default Home;
