import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./views/Home";
import BlogOverview from './views/BlogOverview';
import BlogPost from './views/BlogPost';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<BlogOverview />} />
          <Route path="/blog/:slug" element={<BlogPost />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
